export default {
  data() {
    return {
      pageLoaded: null,
      ticket: null,
      files: [],
      uploadResponseStatus: null,
      timeStamp: null,
      textMessage: null,
      messages: [],
      disableButton: false,
      uuid: null
    }
  },


  methods: {
    keyPressed(event) {

      if (event.keyCode === 13 && (event.shiftKey || event.altKey || event.ctrlKey)) {

        if (event.altKey || event.ctrlKey) {
          document.getElementById('message-input-ticket').innerHTML = document.getElementById('message-input-ticket').innerHTML ? document.getElementById('message-input-ticket').innerHTML : '';
          if (!document.getElementById('message-input-ticket').childElementCount) {
            let createdBr = document.createElement('br');
            document.getElementById('message-input-ticket').appendChild(createdBr);
          }
          document.getElementById('message-input-ticket').appendChild(document.createElement('br'));
          this.placeCaretAtEnd(document.getElementById('message-input-ticket'));
        }

      }
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      this.uploadResponseStatus = "started";
      this.timeStamp = +new Date();
      this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.TICKET, this.timeStamp)
        .then(result => {

          let file = {
            url: this.Constants.CDN_UPLOADS_G + result.uploadName,
            externalID: result.externalID,
            filename: result.uploadName
          };

          this.files.push(file);
          this.uploadResponseStatus = null;
          e.target.value = '';
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.uploadResponseStatus = null;
          e.target.value = '';
        });
    },

    sendMessage() {
      let uploadIDs = this.files.map(function (file) {
        return file.externalID;
      });
      this.disableButton = true;
      this.api.general.addMessageToTicket(this.uuid, this.textMessage, uploadIDs, this.$Progress)
        .then(({data}) => {
          let result = data;
          this.disableButton = false;
          if (result.success) {
            this.ticket.messages.push(result.data);
            this.textMessage = null;
            document.getElementById('message-input-ticket').innerHTML = '';
            this.files = [];
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    },

    deleteFile(uploadID) {
      this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
        .then(({data}) => {
          let result = data;

          if (result.success) {
            this.files = this.files.filter(function (file) {
              return file.externalID !== uploadID
            });

            this.$toasted.global.infoToast({description: result.message});
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    }
  },

  computed: {
    disableToSave() {
      return !this.textMessage || this.disableButton
    }
  },

  created() {
    this.uuid = this.$store.state.routerParams[0];
    this.api.general.getTicket(this.uuid)
      .then(({data}) => {
        let result = data;
        if (result.success) {
          this.ticket = result.data.ticket;

          this.$store.state.indexHTMLTitle = result.data.meta.title;
          this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

          this.Helper.trackEvents.pageView(this.Helper.ticketDetailPageProps(result.data, this.$store.state.routerParams[0]));
        } else {
          this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])
        }
      })
      .catch(err => {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
      });
  }
}
