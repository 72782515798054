<template>
  <div class="settings">
    <app-redirect/>
  </div>

</template>

<script>

  import mixin from './mixin';
  import appRedirect from "../../../components/appRedirect";

  export default {
    components:{appRedirect},
    name: "src-pages-body-tickets-ticketDetail-v1_mobile",
    mixins: [mixin],
		data() {
			return {

      }
		},



	}

</script>
